import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash, FaUserAlt } from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import { useLoader } from "../../context/Loader/LoaderProvider"; // Import the useLoader hook
import Loader from "../../context/Loader/Loader"; // Import the Loader component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { GoDotFill } from "react-icons/go";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from "xlsx";

const UsersList = () => {
  // State for storing user data
  const [usersData, setUsersData] = useState([]);
    const [rolesData, setRolesData] = useState([]);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoader(); // Get the isLoading state and setIsLoading function from LoaderProvider
  const [userId, setUserId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [isExcelModal, setIsExcelModal] = useState(false);



    const fetchRolesData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosPrivate.get("roles");
        if (response.status === 200) {
          setRolesData(response.data?.data);
        }
      } catch (error) {
        if (error.response?.status !== 404) {
          showErrorToast(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchRolesData();
    }, []);

  const handleJobUploadFromExcel = async (file) => {
    if (file === null) {
      showErrorToast("No file selected!");
      return;
    }

    let jsonData = {};

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
     let jsonData = XLSX.utils.sheet_to_json(worksheet);
     const newJSON = jsonData.map((item) => {
       const role = rolesData?.find(
         (role) => role?.name.toLowerCase() === item.role_name.toLowerCase()
       );

       if (!role) {
         showErrorToast(`${item.role_name} Role does not exist: `);
         return;
       }

       return {
         ...item,
         role_id: role.id,
       };
     });

      try {
        const response = await axiosPrivate.post("import-user", {
          data: JSON.stringify(newJSON),
        });
        console.log(response);
        if (response.status === 200) {
          setIsExcelModal(false);
          // Assuming you have 'router' imported from a routing library like 'react-router-dom'
          showSuccessToast("File uploaded successfully");
          fetchData();
        }
        if (response?.data?.emailexists.length > 0) {
          showErrorToast(
            "Email already exists : -" + response?.data?.emailexists[0]
          );
        }
      } catch (error) {
        showErrorToast(error);
      }
    };
  };

  useEffect(() => {
    if (userId) {
      setDeleteConfirmationVisible(true);
    }
  }, [userId]);

  const userDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`user/${id}`);
      if (response.status === 200) {
        showSuccessToast("User deleted successfully");
        fetchData();
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setUserId(null);
    }
  };
  // Function to fetch user data
  const fetchData = async () => {
    try {
      setIsLoading(true); // Set isLoading to true before fetching data
      const response = await axiosPrivate.get("user");
      if (response.status === 200) {
        setUsersData(response?.data?.data);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  // Define table headers
  const headers = [
    { name: "Name", field: "name", sortable: true, classKey: "" },
    { name: "Employee Code", field: "emp_code", sortable: true, classKey: "" },
    { name: "Role", field: "role_name", sortable: true, classKey: "" },
    { name: "Email", field: "email", sortable: true, classKey: "" },
    { name: "Mobile", field: "mobile", sortable: true, classKey: "" },
    { name: "Action", field: "action", classKey: "" },
  ];

  // Prepare data for rendering in DataTable
  const usersListData = usersData?.map((user, index) => {
    let buttons = [];

    // Add Edit button if the user has permission
    // if (authPermissions?.includes("Users-Update")) {
    buttons.push(
      <Link
        key={`editButton_${user.id}`}
        to={`/users/edit/${user.id}`}
        style={{ border: "none", background: "none", padding: "6px" }}
        title="Edit"
      >
        <FaEdit color="green" size={13} />
      </Link>
    );
    // }

    // Add Permission button
    // if (authPermissions?.includes("Users-Update")) {
    buttons.push(
      <Link
        key={`userButton_${user.id}`}
        to={`/users/permissions/${user.id}`}
        style={{ border: "none", background: "none", padding: "6px" }}
        title="Permission"
      >
        <FaUserAlt color="#232323" size={13} />
      </Link>
    );
    // }

    // Assign action buttons to the user data
    user["action"] = buttons.length > 0 ? buttons : "-";

    return { ...user, index: index + 1 };
  });

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        {/* Render user management section if user has permission */}
        {/* {authPermissions?.includes("Users-Create") && ( */}
        <div className="d-flex">
          <Link to="add" className="text-decoration-none pr-3">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i className="fe fe-plus mr-2" />
              Add
            </button>
          </Link>
          <label htmlFor="excel-upload">
            <input
              id="excel-upload"
              type="file"
              onChange={(e) => handleJobUploadFromExcel(e.target.files[0])}
              style={{ display: "none" }}
            />
            <SiMicrosoftexcel
              size={35}
              title="Upload Excel File"
              style={{ cursor: "pointer" }}
              color="var(--green)"
            />
          </label>
        </div>

        {/* )} */}
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Search Users"
        />
      </div>
    );
  };

  const header = renderHeader();

  const statusBodyTemplate = (rowData) => {
    return rowData?.checked_in === 1 ? (
      <GoDotFill size={20} color="#73dc45" title="Active" />
    ) : (
      <GoDotFill size={20} color="#ff1d1d" title="Inactive" />
    );
  };

  const deleteBodyTemplate = (rowData) => {
    return (
      <button
        type="button"
        onClick={() => {
          setUserId(rowData.id);
        }}
        title="Delete"
        style={{
          border: "none",
          background: "none",
          padding: "6px",
          textAlign: "left",
        }}
      >
        <FaTrash color="red" size={13} />
      </button>
    );
  };

  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='content'>
            <div className='page-header'>
              <h4 className='m-1 pl-3'>Users</h4>
            </div>
            {/* Render user list section */}
            <div className='card'>
              <div className='card-body'>
                <div className='table-responsive'>
                  {/* Render DataTable component */}
                  <DataTable
                    value={usersListData}
                    dataKey='id'
                    header={header}
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 50, 100, 150]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                  >
                    <Column
                      field='index'
                      header='Sr. No.'
                      headerStyle={{ width: "5%" }}
                      bodyStyle={{ textAlign: "left" }}
                    />
                    {headers.map((header, index) => {
                      return (
                        <Column
                          key={index}
                          field={header?.field}
                          body={
                            header?.field === "status"
                              ? statusBodyTemplate
                              : false
                          }
                          header={header?.name}
                          sortable={header?.field !== "action"}
                          bodyStyle={{
                            textAlign:
                              header?.field === "status"
                                ? "center"
                                : header?.field === "action"
                                ? "right"
                                : "left",
                          }}
                          headerStyle={{
                            width: header?.field === "action" ? "10%" : "",
                            paddingLeft:
                              header?.field === "action" ? "110px" : "",
                          }}
                        />
                      );
                    })}
                    <Column
                      field='delete_handler'
                      body={deleteBodyTemplate}
                      headerStyle={{ width: "15%" }}
                      bodyStyle={{ textAlign: "left" }}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
            <Dialog
              visible={deleteConfirmationVisible}
              onHide={() => {
                setDeleteConfirmationVisible(false);
                setUserId(null);
              }}
              
              footer={
                <>
                  <Button
                    label='Cancel'
                    className='btn btn-secondary'
                    onClick={() => {
                      setUserId(null);
                      setDeleteConfirmationVisible(false);
                    }}
                  />
                  <Button
                    label='Delete'
                    className='btn btn-primary ml-3'
                    
                    onClick={() => {
                      userDeleteHandler(userId);
                      setDeleteConfirmationVisible(false);
                    }}
                  />
                </>
              }
            >
              <p>Are you sure you want to delete this user!</p>
            </Dialog>
          </div>
        </>
      )}
    </>
  );
};

export default UsersList;
