import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import Logo from "../../assets/images/ievoLogo.png";
import { axiosPublic } from "../../hooks/axiosPublic";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const forgotPasswordSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is Required"),
  });

  // Initial form values
  const initialValues = {
    email: "",
  };

  
  const handleSubmit = async (values) => {
    try {
      const response = await axiosPublic.post("reset-password", values);
      if (response.status === 200) {
        showSuccessToast("Password has been sent to your email!");
        navigate("/login");
      }
    } catch (error) {
      showErrorToast(error.response?.data?.errors[0].msg || 'Something went wrong');
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className='d-flex justify-content-center aligin-items-center w-100 m-3'>
      <div
        className='card'
        style={{ width: "350px" }}
      >
        <div className='text-center mb-2'>
          {/* Logo */}
          <Link
            className='header-brand'
            to='/'
          >
            <img
              style={{
                height: "32px",
                width: "32px",
              }}
              src={Logo}
              alt='logo'
            />
          </Link>
        </div>

        <div className='card-body'>
          <form onSubmit={formik.handleSubmit}>
            {/* Forgot Password form */}
            <div className='card-title'>Forgot Password</div>

            {/* Email input */}
            <div className='form-group'>
              <label className='form-label'>Email address</label>
              <input
                type='email'
                name='email'
                className='form-control'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.email}
                </div>
              )}
            </div>
            {/* Submit button */}
            <div className='form-footer'>
              <input
                type='submit'
                className='btn btn-primary btn-block'
                value='Send Password'
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
