import React, { useEffect, useState } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import Loader from "../../context/Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useLoader } from "../../context/Loader/LoaderProvider";

const AuditLogs = () => {
  const [logsData, setLogsData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  /**
   * Initializes the state for the global filter on the audit logs data table.
   * The global filter is set to an empty string and uses the CONTAINS match mode.
   */
  const [filters, setFilters] = useState({
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });


  /**
   * Fetches audit logs data from the server and updates the logsData state.
   *
   * @returns {Promise<void>}
   */
  const fetchData = async () => {
    try {
      // Set the isLoading state to true to show the loader
      setIsLoading(true);

      // Make the GET request to the server to fetch the audit logs data
      const response = await axiosPrivate.get("/audit-logs");

      // Check if the response status is 200 (OK)
      if (response.status === 200) {
        // Modify the response data to add an index for serial numbers
        const modifiedLogsData = response.data?.data.map((log, index) => ({
          ...log,
          // created_at: new Date(log.created_at).toLocaleDateString("en-GB"),
          index: index + 1, // Add index for serial numbers
        }));

        // Update the logsData state with the modified logs data
        setLogsData(modifiedLogsData);
      }
    } catch (error) {
      // Show an error toast with the error message
      showErrorToast("Error fetching audit logs data");
    } finally {
      // Set the isLoading state to false to hide the loader
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  /**
   * Function to handle global filter change
   *
   * @param {Object} event - The event object
   * @returns {void}
   */
  const onGlobalFilterChange = (event) => {
    // Get the value from the event target
    const { value } = event.target;

    // Update the filters state with the new global filter value and match mode
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };



  const renderHeader = () => {
    // Get the value from filters state
    const value = filters["global"]?.value || "";

    return (
      <div className='d-flex justify-content-between align-items-center'>
        {/* Header title */}
        <h4 className='mt-2 mb-4'>Audit Logs</h4>
        {/* Global search input */}
        <InputText
          type='search'
          value={value}
          onChange={onGlobalFilterChange}
          // Placeholder text
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader();

  /**
   * Renders the Audit Logs page, which displays a table of audit log data.
   * The table includes columns for Sr. No., Employee Code, User Name, Role, Created At, User Email, Contact No., and Activity.
   * The page also includes a global search input to filter the audit log data.
   * The table is paginated and allows the user to select the number of rows to display per page.
   */
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='content'>
          <div className='card'>
            
              <div className='table-responsive'>
                <DataTable
                  value={logsData}
                  dataKey='id'
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={20}
                  rowsPerPageOptions={[20, 50, 100, 150]}
                  paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                  currentPageReportTemplate='{first} to {last} of {totalRecords}'
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    // headerStyle={{ width: "10%" }}
                    sortable
                    bodyStyle={{ textAlign: "left" }}
                  />
                  <Column
                    field='model_name'
                    header='Module'
                    sortable
                  />

                  <Column
                    field='user_name'
                    header='User Name'
                    sortable
                  />
                  <Column
                    field='role_name'
                    header='Role'
                    sortable
                  />
                  <Column
                    field='created_at'
                    header='Created At'
                    body={(rowData) =>
                      rowData?.created_at
                        ? rowData?.created_at.split("T")[0]
                        : "-"
                    }
                    sortable
                  />
                  <Column
                    field='user_email'
                    header='User Email'
                    sortable
                  />
                  <Column
                    field='mobile_number'
                    header='Contact No.'
                    sortable
                  />
                  <Column
                    field='action'
                    header='Activity'
                    sortable
                  />
                </DataTable>
              </div>
           
          </div>
        </div>
      )}
    </>
  );
};

export default AuditLogs;
