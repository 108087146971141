import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { MdQrCodeScanner } from "react-icons/md";
import { FaTimes } from "react-icons/fa";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import styles from "./OrdersList.module.css";
import axios from "axios";
import { TiTick } from "react-icons/ti";
import { CiEdit } from "react-icons/ci";
import { Dialog } from "primereact/dialog";
import ScanQr from "./ScanQr";
import { TabPanel, TabView } from "primereact/tabview";

const OrdersList = () => {
  const { isLoading, setIsLoading } = useLoader();
  const [ordersData, setOrdersData] = useState();
  const [scanQrDialog, setScanQrDialog] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [subscriptions, setSubscriptionData] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("order");
      if (response.status === 200) {
        setOrdersData(response?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const subscriptionData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("subscription");
      if (response.status === 200) {
        setSubscriptionData(response?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    subscriptionData();
  }, []);

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Search Order"
          style={{ flex: "1" }}
        />

        <Link
          to=""
          className="text-decoration-none  text-center ml-2"
          style={{ minWidth: "120px" }}
          onClick={() => setScanQrDialog(true)}
        >
          <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            {/* <i className='fe fe-plus mr-2' /> */}
            <MdQrCodeScanner className="mr-2" size={20} />
            Scan QR
          </button>
        </Link>
        {activeTabIndex == 0 ? (
          <Link
            to="/orders/create"
            className="text-decoration-none  text-center ml-2"
            style={{ minWidth: "150px" }}
          >
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i className="fe fe-plus mr-2" />
              Create Order
            </button>
          </Link>
        ) : (
          <Link
            to="/subscription-create"
            className="text-decoration-none  text-center ml-2"
            style={{ minWidth: "150px" }}
          >
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i className="fe fe-plus mr-2" />
              Create Subscription
            </button>
          </Link>
        )}
        {/* )} */}
      </div>
    );
  };

  const printKot = async (userName, orderId) => {
    try {
      const response = await axios.post(
        `http://localhost:8000/api/print-receipt`,
        {
          user_name: userName,
          order_id: orderId,
        }
      );

      if (response?.status === 200) {
        showSuccessToast("Please receive your receipt!");
      }
    } catch (error) {
      // showErrorToast(error.message);
    }
  };

  const updateOrder = async (orderId) => {
    try {
      const response = await axiosPrivate.put(`/payment-status/${orderId}`);

      if (response?.status === 200) {
        showSuccessToast("Order Status Updated");
        fetchData();
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const updateSubscription = async (orderId) => {
    try {
      const response = await axiosPrivate.put(
        `/subscription-payment-status/${orderId}`
      );

      if (response?.status === 200) {
        showSuccessToast("Subscription Status Updated");
        subscriptionData();
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const cancelOrder = async (orderId) => {
    try {
      const response = await axiosPrivate.put(`/cancel-order/${orderId}`);

      if (response?.status === 200) {
        showSuccessToast("Order Cancelled");
        fetchData();
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  const header = renderHeader();
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='content'>
            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) => setActiveTabIndex(e.index)}
            >
              <TabPanel header='Regular Orders'>
                <div className='card'>
                  <DataTable
                    value={ordersData}
                    showGridlines
                    stripedRows
                    dataKey='id'
                    header={header}
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    paginator
                    rowsPerPageOptions={[10, 20, 30, 50]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                    rows={10}
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    <Column
                      field='id'
                      header='Order ID'
                      bodyStyle={{ textAlign: "left" }}
                      style={{ width: "8%" }}
                      sortable
                    />

                    <Column
                      field='order_no'
                      header='Order No.'
                      bodyStyle={{ textAlign: "left" }}
                      body={(rowData) =>
                        rowData?.order_no === 0 ? "-" : rowData?.order_no
                      }
                      style={{ width: "8%" }}
                      // sortable
                    />

                    <Column
                      field='user_name'
                      header='Name'
                      body={(rowData) => rowData?.user_name.toUpperCase()}
                      sortable
                    />

                    <Column
                      field='net_amount'
                      header='Amount'
                      sortable
                    />
                    <Column
                      field='payment_mode'
                      header='Payment Mode'
                      body={(rowData) => rowData?.payment_mode.toUpperCase()}
                      sortable
                    />
                    <Column
                      field='payment_status'
                      header='Payment Status'
                      body={(rowData) =>
                        rowData?.payment_status === "due" ? (
                          <Tag
                            severity='danger'
                            value='DUE'
                          />
                        ) : rowData?.payment_status === "paid" ? (
                          <Tag
                            severity='success'
                            value='PAID'
                          />
                        ) : rowData?.payment_status === "refunded" ? (
                          <Tag
                            severity='warning'
                            value='Refunded'
                          />
                        ) : (
                          "-"
                        )
                      }
                      sortable
                    />
                    <Column
                      field='payment_status'
                      header='Action'
                      body={(rowData) =>
                        rowData?.payment_status === "paid" ? (
                          <button
                            className={styles.actionBtn}
                            onClick={() =>
                              printKot(rowData?.user_name, rowData?.uu_id)
                            }
                          >
                            Print Reciept
                          </button>
                        ) : rowData?.payment_status === "due" ? (
                          <button
                            className={styles.actionBtn}
                            onClick={() => updateOrder(rowData?.uu_id)}
                          >
                            Confirm Payment
                          </button>
                        ) : (
                          "-"
                        )
                      }
                      sortable
                    />
                    <Column
                      style={{ minWidth: "100px" }}
                      header='Status'
                      body={(rowData) =>
                        rowData?.order_status == "pending" &&
                        rowData?.payment_status !== "refunded" ? (
                          <button
                            className={styles.cancelButton}
                            onClick={() => cancelOrder(rowData?.uu_id)}
                          >
                            <FaTimes className={styles.icon} />
                          </button>
                        ) : rowData?.order_status == "completed" &&
                          rowData?.payment_status !== "refunded" ? (
                          <button className={styles.submitButton}>
                            <TiTick className={styles.submitIcon} />
                          </button>
                        ) : (
                          "-"
                        )
                      }
                    />
                  </DataTable>

                  <Dialog
                    visible={scanQrDialog}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!scanQrDialog) return;
                      setScanQrDialog(false);
                    }}
                  >
                    <ScanQr />
                  </Dialog>
                </div>
              </TabPanel>
              <TabPanel header='Subscriptions'>
                <div className='card'>
                  <DataTable
                    value={subscriptions}
                    showGridlines
                    stripedRows
                    dataKey='id'
                    header={header}
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    paginator
                    rowsPerPageOptions={[10, 20, 30, 50]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                    rows={10}
                    tableStyle={{ minWidth: "50rem" }}
                  >
                    <Column
                      field='id'
                      header='Order ID'
                      bodyStyle={{ textAlign: "left" }}
                      style={{ width: "8%" }}
                      sortable
                    />

                    <Column
                      field='user_name'
                      header='Name'
                      body={(rowData) => rowData?.user_name.toUpperCase()}
                      sortable
                    />

                    <Column
                      field='total_amount'
                      header='Amount'
                      sortable
                    />
                    <Column
                      field='remainingDays'
                      header='Remaining Days'
                      sortable
                    />
                    <Column
                      field='payment_mode'
                      header='Payment Mode'
                      body={(rowData) => rowData?.payment_mode?.toUpperCase()}
                      sortable
                    />
                    <Column
                      field='payment_status'
                      header='Payment Status'
                      body={(rowData) =>
                        rowData?.payment_status === "due" ? (
                          <Tag
                            severity='danger'
                            value='DUE'
                          />
                        ) : rowData?.payment_status === "paid" ? (
                          <Tag
                            severity='success'
                            value='PAID'
                          />
                        ) : rowData?.payment_status === "refunded" ? (
                          <Tag
                            severity='warning'
                            value='Refunded'
                          />
                        ) : (
                          "-"
                        )
                      }
                      sortable
                    />
                    <Column
                      field='payment_status'
                      header='Action'
                      body={(rowData) => (
                        <div>
                          {rowData?.payment_status === "due" ? (
                            <button
                              className={styles.actionBtn}
                              onClick={() => updateSubscription(rowData?.id)}
                            >
                              Confirm Payment
                            </button>
                          ) : (
                            "Confirmed"
                          )}
                          <Link
                            to={`/subscription/${rowData.id}`}
                            style={{ marginLeft: "20px" }} // Add some margin for better spacing
                          >
                            <CiEdit
                              size={20}
                              color='green'
                            />
                          </Link>
                        </div>
                      )}
                      sortable
                    />
                  </DataTable>

                  <Dialog
                    visible={scanQrDialog}
                    style={{ width: "50vw" }}
                    onHide={() => {
                      if (!scanQrDialog) return;
                      setScanQrDialog(false);
                    }}
                  >
                    <ScanQr />
                  </Dialog>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </>
      )}
    </>
  );
};

export default OrdersList;
