import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const AddEditMenuCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [text, setText] = useState("Add");

  useEffect(() => {
    const getMenuDetails = async () => {
      try {
        if (id) {
          const res = await axiosPrivate.get(`menuCategory/${id}`);
          formik.setValues(res?.data?.data);
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    if (id) {
      getMenuDetails();
    }
  }, [id]);

  // Form validation schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Menu Category is required"),
    description: Yup.string().required("Description is required"),
  });

  // Initial form values
  const initialValues = {
    title: "",
    description: "",
  };

  // Form submission handling
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (id) {
        await axiosPrivate.post(`menuCategory/${id}`, formData);
        navigate("/menu-category");
        showSuccessToast("Category updated successfully");
      } else {
        await axiosPrivate.post(`menuCategory`, formData);
        navigate("/menu-category");
        showSuccessToast("Category added successfully");
      }
    } catch (err) {
      showErrorToast(err.message);
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (id) {
      setText("Edit");
    }
  }, [id]);


  return (
    <div className="content">
      <div
        className="section-body d-flex justify-content-center align-items-center"
        style={{ minHeight: `calc(100dvh - 106px)` }}
      >
        <div className="card " style={{ width: "30%" }}>
          <form onSubmit={formik.handleSubmit}>
            <div className="card-header">
              <strong>{text} Menu Category</strong>
            </div>
            <div className="card-body">
              <div className="row clearfix">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">Category Title</label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className="form-control"
                     
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue("title", formik.values.title);
                      }}
                      value={formik.values?.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.title}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <input
                      id="description"
                      name="description"
                      type="text"
                      className="form-control"
                     
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue(
                          "description",
                          formik.values?.description
                        );
                      }}
                      value={formik.values?.description}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.description}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-12 text-right">
                  <hr className="mt-4" />
                  <button
                    type="button"
                    className="btn btn-secondary mx-1"
                    onClick={() => navigate("/menu-category")}
                  >
                    CLOSE
                  </button>
                  <button type="submit" className="btn btn-primary">
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditMenuCategory;
