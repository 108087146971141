import styles from "./Loader.module.css";

const Loader = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.newtons_cradle}>
        <div className={styles.newtons_cradle__dot}></div>
        <div className={styles.newtons_cradle__dot}></div>
        <div className={styles.newtons_cradle__dot}></div>
        <div className={styles.newtons_cradle__dot}></div>
      </div>
    </div>
  );
};

export default Loader;
