import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import TopNavBar from "../../Layouts/TopNavBar";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import Select from "react-select";

// Functional component for adding or editing a user
const AddEditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // Using the useNavigate hook to navigate
  const [rolesData, setRolesData] = useState([]);

  // Fetch Plant data if id is provided and user wants to update
  useEffect(() => {
    const getUser = async () => {
      try {
        if (id) {
          const res = await axiosPrivate.get(`user/${id}`);
          formik.setValues(res?.data?.data);
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    if (id) {
      getUser();
    }
  }, [id]);

  // Function to handle mobile number validation
  const mobileValidation = (event) => {
    // ... (code for mobile number validation)
    if (event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 9) {
      return; // Allow the keypress
    }
    if (
      event?.target?.value?.length >= 10 ||
      event.keyCode === 69 ||
      event.keyCode === 189
    ) {
      event.preventDefault();
    }
  };

  // Validation schema for the user form
  const userSchema = Yup.object({
    name: Yup.string().required("Name is required").trim(),
    emp_code: Yup.string().required("Employee Code is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    department: Yup.string().required("Department is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Invalid mobile number")
      .required("Phone number is required"),
    role: Yup.string().required("Role is required"),
    ...(id
      ? {}
      : {
          password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required"),
          confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm password is required"),
        }),
  });

  // Formik hook for form management
  const formik = useFormik({
    initialValues: {
      name: "",
      emp_code: "",
      email: "",
      mobile: "",
      role: "",
      password: "",
      confirm_password: "",
      department: "",
    },
    validationSchema: userSchema, // Using the userSchema for validation
    onSubmit: async (values) => {
      try {
        const { confirm_password, ...userValues } = values;
        if (id) {
          const response = await axiosPrivate.put(`user/${id}`, userValues);
          if (response.status === 200) {
            navigate("/users");
            showSuccessToast("User updated successfully");
          }
        } else {
          const res = await axiosPrivate.post(`user`, userValues);
          if (res.status === 200) {
            navigate("/users");
            showSuccessToast("User added successfully");
          }
        }
      } catch (err) {
        console.log(err);
        showErrorToast(
          err.response?.data?.errors[0] ||
            err?.response?.data.errors[0].msg ||
            err?.message
        );
      }
    },
  });

  const { touched, errors } = formik;

  const fetchRoleData = async () => {
    try {
      const response = await axiosPrivate.get("roles");
      if (response.status === 200) {
        setRolesData(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  // Load data on component mount
  useEffect(() => {
    fetchRoleData();
  }, []);
  // JSX for rendering the user form

  const departments = [
    { id: "1", name: "Designing" },
    { id: "2", name: "Manufacturing" },
    { id: "3", name: "Marketing" },
  ];

  return (
    <div className='content'>
      {/* <TopNavBar links={{ list: "/users", add: "/users/add" }} /> */}
      <div
        className='section-body d-flex justify-content-center align-items-center'
        style={{ minHeight: `calc(100dvh - 106px)` }}
      >
        <div
          className='card  '
          style={{ width: "70%" }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className='card-header'>
              <strong>{id ? "Edit User" : "Add User"}</strong>
            </div>
            <div className='card-body'>
              <div className='row clearfix'>
                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>User Name</label>
                    <input
                      id='name'
                      type='text'
                      name='name'
                      className='form-control'
                      placeholder='User-Name *'
                      value={formik?.values?.name}
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldValue("name", formik.values.name.trim());
                      }}
                    />
                    {touched?.name && errors?.name ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors?.name}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Employee Code</label>
                    <input
                      id='emp_code'
                      type='number'
                      name='emp_code'
                      className='form-control'
                      placeholder='Employee Code *'
                      value={formik?.values?.emp_code}
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.replace(/^0+/, ""); // Remove leading zeros
                        formik.setFieldValue("emp_code", trimmedValue); // Update formik value
                        formik.handleBlur(e);
                      }}
                    />
                    {touched.emp_code && errors.emp_code ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.emp_code}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Email</label>
                    <input
                      id='email'
                      type='email'
                      name='email'
                      className='form-control'
                      placeholder='email *'
                      value={formik?.values?.email}
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);

                        formik.setFieldValue(
                          "email",
                          formik.values.email.trim()
                        );

                        e.target.value = formik.values.email.trim();
                      }}
                    />
                    {touched.email && errors.email ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Password</label>
                    <input
                      id='password'
                      type='password'
                      name='password'
                      className='form-control'
                      placeholder='Password *'
                      value={formik?.values?.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {touched.password && errors.password && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Confirm Password</label>
                    <input
                      id='confirm_password'
                      type='password'
                      name='confirm_password'
                      className='form-control'
                      placeholder='Confirm password *'
                      value={formik?.values?.confirm_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {touched.confirm_password && errors.confirm_password && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.confirm_password}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Phone Number</label>
                    <input
                      id='phone_no'
                      type='number'
                      name='mobile'
                      onKeyDown={(event) => mobileValidation(event)}
                      className='form-control'
                      placeholder='Phone Number *'
                      value={formik?.values?.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {touched.mobile && errors.mobile ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.mobile}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Role</label>
                    <select
                      className='form-control show-tick'
                      id='role'
                      name='role'
                      value={formik?.values?.role}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value=''>--Select Role--</option>
                      {rolesData?.map((role, index) => (
                        <option
                          key={index}
                          value={role.id}
                        >
                          {role?.name}
                        </option>
                      ))}
                    </select>
                    {touched.role && errors.role ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.role}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Department</label>
                    <select
                      className='form-control show-tick'
                      id='department'
                      name='department'
                      value={formik.values.department}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value=''>--Select Department--</option>
                      {departments.map((dept) => (
                        <option
                          key={dept.id}
                          value={dept.name}
                        >
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.department && formik.errors.department ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.department}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='col-12 text-right'>
                  <hr className='mt-4' />
                  <button
                    type='button'
                    id='button_1'
                    className='btn btn-secondary mx-1'
                    onClick={() => navigate("/users")}
                  >
                    CLOSE
                  </button>

                  <button
                    type='submit'
                    id='button_2'
                    className='btn btn-primary'
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEditUser;
