import React, { useEffect, useState } from "react";
import styles from "./CustomerDashboard.module.css";
import OrderFoodIcon from "../../assets/images/order_food.svg";
import FixedMealIcon from "../../assets/images/fixed_meal.svg";
import FoodOrder from "../FoodOrder/FoodOrder";
import FixedMeal from "../FixedMeal/FixedMeal";
import CustomerFooter from "../CustomerFooter/CustomerFooter";
import FooterDetails from "../CustomerFooter/FooterDetails";
import { useLoader } from "../../context/Loader/LoaderProvider";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import Loader from "../../context/Loader/Loader";
import { useNavigate } from "react-router-dom";

const CustomerDashboard = () => {
  const [activeTab, setActiveTab] = useState("orderFood");
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [regularMealData, setRegularMealData] = useState([]);
  const [fixedMealData, setFixedMealData] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [checkoutData, setCheckoutData] = useState([]);
  const [showQr, setShowQr] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [guestOrderData, setGuestOrderData] = useState({});
  const [cartData, setCartData] = useState([]); // State for cart data

  const [hasOrderData, setHasOrderData] = useState(false);

  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoader();
  const [paymentPending, setPaymentPending] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const cartList = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : [];


  useEffect(() => {
    // Redirect to login page if userData is not present or essential properties are missing
    if (!userData || !userData.id || !userData.userName) {
      navigate("/login");
      window.location.reload();
    }
  }, [navigate, userData]);



  // useEffect(() => {
  //   setCartData([]);
  //   localStorage.setItem('cartData', JSON.stringify([]));   
  // }, [activeTab]);


  useEffect(() => {    
    if (cartList) {
     setCartData(cartList);
   }
  }, []);



  const handleDetailsClick = () => {
    setIsDetailsVisible((prev) => !prev);
  };

  const fetchMenu = async () => {
    try {
      setIsLoading(true);

      const response = await axiosPrivate.get("/menu");
      if (response.status === 200) {
        const Data = response?.data?.data;
        setRegularMealData(
          Data?.filter((item) => item.menu_type === "Regular")
        );
        setFixedMealData(
          Data?.filter((item) => item.menu_type === "Fixed Meal")
        );
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMenu();
  }, []);

  // const getCheckoutData = async () => {
  //   try {
  //     const response = await axiosPrivate.get(`/checkout/${userData?.id}`);

  //     if (response.status === 200) {
  //       setCheckoutData(response?.data?.data?.items);
  //       setTotalQuantity(response?.data?.data?.total_quantity);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getCheckoutData();
  // }, []);

  // const updateCheckoutData = () => {
  //   getCheckoutData();
  // };

  const checkSubcription = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `/subscription-item/${userData?.id}`
      );
      if (response?.status === 200) {
        setOrderData(response?.data?.Data);
        if (response?.data?.Data?.items?.length) {
          setShowQr(true);
          // setActiveTab("fixedMeal");
        }
        if (response?.data?.data?.payment_approved === false) {
          setPaymentPending(true);
        }
        setHasOrderData(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkSubcription();
  }, []);

  const updateQr = () => {
    checkSubcription();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <div className={styles.customerWrapper}>
            <div className={styles.bodyWrapper}>
              {/* Order Type Buttons */}
              <div className={styles.btnWrapper}>
                <button
                  className={styles.orderTypeBtn}
                  style={
                    activeTab === "orderFood"
                      ? {
                          background:
                            "linear-gradient(135deg,  #ff4c29 1%,#eb1c24 100%)",
                        }
                      : {}
                  }
                  onClick={() => setActiveTab("orderFood")}
                >
                  <span
                    style={{
                      color:
                        activeTab === "orderFood"
                          ? "#ffffff"
                          : "var(--primary-button-bg)",
                    }}
                  >
                    Order Food
                  </span>
                  <img
                    src={OrderFoodIcon}
                    alt='order_food_icon'
                    style={{
                      filter:
                        activeTab === "orderFood" ? "brightness(100)" : "",
                    }}
                  />
                </button>
                <button
                  className={styles.orderTypeBtn}
                  style={
                    activeTab === "fixedMeal"
                      ? {
                          background:
                            "linear-gradient(135deg,  #ff4c29 1%,#eb1c24 100%)",
                        }
                      : {}
                  }
                  onClick={() => setActiveTab("fixedMeal")}
                >
                  <span
                    style={{
                      color:
                        activeTab === "fixedMeal"
                          ? "#ffffff"
                          : "var(--primary-button-bg)",
                    }}
                  >
                    Fixed Meal
                  </span>
                  <img
                    src={FixedMealIcon}
                    alt='fixed_meal_icon'
                    style={{
                      filter:
                        activeTab === "fixedMeal" ? "brightness(100)" : "",
                    }}
                  />
                </button>
              </div>

              {activeTab === "orderFood" && (
                <FoodOrder
                  menuData={regularMealData}
                  activeTab={activeTab}
                  setCartData={setCartData}
                  cartData={cartData}
                />
              )}
              {activeTab === "fixedMeal" && (
                <FixedMeal
                  menuData={fixedMealData}
                  activeTab={activeTab}
                  orderData={orderData}
                  hasOrderData={hasOrderData}
                  showQr={showQr}
                  updateQr={updateQr}
                  paymentPending={paymentPending}
                  setCartData={setCartData}
                  cartData={cartData}
                />
              )}
            </div>
            {/* Customer Footer  */}
            <CustomerFooter
              onDetailsClick={handleDetailsClick}
              totalQuantity={totalQuantity}
              cartData={cartData}
              setCartData={setCartData}
            />

            {isDetailsVisible && (
              <FooterDetails
                onClose={handleDetailsClick}
                checkoutData={checkoutData}
                cartData={cartData}
                setCartData={setCartData}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CustomerDashboard;
