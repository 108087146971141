import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash, FaUserAlt } from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useLoader } from "../../context/Loader/LoaderProvider"; // Import the useLoader hook
import Loader from "../../context/Loader/Loader"; // Import the Loader component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { GoDotFill } from "react-icons/go";
import AddBalance from "./AddBalance";

const UserBalanceListing = () => {
  // State for storing user data
  const [usersData, setUsersData] = useState([]);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoader(); // Get the isLoading state and setIsLoading function from LoaderProvider
  const [userId, setUserId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [refreshTable,setRefreshTable] = useState(false);

  useEffect(() => {
    if (userId) {
      setDeleteConfirmationVisible(true);
    }
  }, [userId]);

  const userDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`user/${id}`);
      if (response.status === 200) {
        showSuccessToast("User deleted successfully");
        fetchData();
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setUserId(null);
    }
  };
  // Function to fetch user data
  const fetchData = async () => {
    try {
      setIsLoading(true); // Set isLoading to true before fetching data
      const response = await axiosPrivate.get("user");
      if (response.status === 200) {
        console.log(response);
        setUsersData(response?.data?.data);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        navigate("/forbidden");
      } else if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  useEffect(() => {
    fetchData();
    setRefreshTable(false); // Fetch data on component
  },[refreshTable]);

 

  // Define table headers
  const headers = [
    { name: "Name", field: "name", sortable: true, classKey: "" },
    { name: "Email", field: "email", sortable: true, classKey: "" },
    {
      name: "Wallet Balance",
      field: "wallet_balance",
      sortable: true,
      classKey: "",
    },
  ];

  // Prepare data for rendering in DataTable
  const usersListData = usersData?.map((user, index) => {
    return { ...user, index: index + 1 };
  });

  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="d-flex justify-content-between w-100">
        <InputText
          type="search"
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder="Search Users"
        />
      </div>
    );
  };

  const header = renderHeader();

  const statusBodyTemplate = (rowData) => {
    return rowData?.checked_in === 1 ? (
      <GoDotFill size={20} color="#73dc45" title="Active" />
    ) : (
      <GoDotFill size={20} color="#ff1d1d" title="Inactive" />
    );
  };

  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='content'>
            <div className='page-header'>
              <h4 className='m-1 pl-3'>User Wallet</h4>
            </div>
            <div className='m-1 pl-3'>
              <AddBalance setRefreshTable={setRefreshTable} />
            </div>
            {/* Render user list section */}
            <div className='card'>
              <div className='table-responsive'>
                {/* Render DataTable component */}
                <DataTable
                  value={usersListData}
                  dataKey='id'
                  header={header}
                  filters={filters}
                  showGridlines
                  stripedRows
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                    rows={20}
                   
                  tableStyle={{ minWidth: "50rem" }}
                  rowsPerPageOptions={[20, 50, 100, 150]}
                  paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                  currentPageReportTemplate='{first} to {last} of {totalRecords}'
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    headerStyle={{ width: "5%" }}
                    bodyStyle={{ textAlign: "left" }}
                  />
                  {headers.map((header, index) => {
                    return (
                      <Column
                        key={index}
                        field={header?.field}
                        body={
                          header?.field === "status"
                            ? statusBodyTemplate
                            : false
                        }
                        header={header?.name}
                        sortable={header?.field !== "action"}
                        bodyStyle={{
                          textAlign:
                            header?.field === "status"
                              ? "center"
                              : header?.field === "action"
                              ? "right"
                              : "left",
                        }}
                        headerStyle={{
                          width: header?.field === "action" ? "10%" : "",
                          paddingLeft:
                            header?.field === "action" ? "110px" : "",
                        }}
                      />
                    );
                  })}
                </DataTable>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserBalanceListing;
