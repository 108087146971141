import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { CiSquarePlus } from "react-icons/ci";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import Select from "react-select";

const AddBalance = ({ setRefreshTable }) => {
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await axiosPrivate.get(`user`);
        setUsers(
          res.data.data
            ?.sort((a, b) =>
              a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase())
            )
            .map((user) => ({
              value: user.id,
              label: `${user.name} - ${user.emp_code}`,
            }))
        );
      } catch (err) {
        showErrorToast(err.message);
      }
    };
    getUsers();
  }, []);

  // Form validation schema
  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required("Name is required"),
    amount: Yup.number().required("Amount is required"),
    payment_mode: Yup.string().required("Payment mode is required"),
  });

  // Initial form values
  const initialValues = {
    user_id: "",
    amount: "",
    transaction_type: "credit",
    description: "",
    payment_mode: "",
  };

  // Form submission handling
  const handleSubmit = async (values) => {
    try {
      await axiosPrivate.post(`wallet`, values);
      showSuccessToast("Amount Credited");
      setRefreshTable(true);
      setIsOpen(false);
    } catch (err) {
      showErrorToast(err.message);
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });


  const paymentModeOptions = [
    { value: "upi", label: "UPI" },
  ];

  return (
    <div>
      <div>
        <button
          className='btn btn-outline'
          style={{ background: "var(--primary-button-bg)" , color: '#fff' }}
          onClick={() => setIsOpen(true)}
        >
          <CiSquarePlus size={40} />
          AddBalance
        </button>
      </div>
      {isOpen && (
        <form
          onSubmit={formik.handleSubmit}
          className='my-3'
        >
          <div className='row '>
            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='form-label'>Customer Name</label>
                <Select
                  id='user_id'
                  name='user_id'
                  options={users}
                  placeholder='Search or select customer name'
                  onChange={(option) =>
                    formik.setFieldValue("user_id", option.value)
                  }
                  onBlur={formik.handleBlur}
                  value={users.find(
                    (option) => option.value === formik.values.user_id
                  )}
                />
                {formik.touched.user_id && formik.errors.user_id && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.user_id}
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='form-label'>Payment Mode</label>
                <Select
                  id='payment_mode'
                  name='payment_mode'
                  placeholder='Select payment mode'
                  options={paymentModeOptions}
                  onChange={(option) =>
                    formik.setFieldValue("payment_mode", option.value)
                  }
                  onBlur={formik.handleBlur}
                  value={paymentModeOptions.find(
                    (option) => option.value === formik.values.payment_mode
                  )}
                />
                {formik.touched.payment_mode && formik.errors.payment_mode && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.payment_mode}
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='form-label'>Amount</label>
                <input
                  type='number'
                  id='amount'
                  name='amount'
                  placeholder='Enter amount'
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.amount}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.amount}
                  </div>
                )}
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group'>
                <label className='form-label'>Description</label>
                <textarea
                  placeholder='Enter description'
                  id='description'
                  name='description'
                  className='form-control'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.description}
                />
                {formik.touched.description && formik.errors.description && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.description}
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 text-right'>
              <hr className='mt-4' />
              {/* Close button */}
              <button
                type='button'
                className='btn btn-secondary mx-1'
                onClick={() => setIsOpen(false)}
              >
                CLOSE
              </button>
              {/* Submit button */}
              <button
                type='submit'
                className='btn btn-primary'
              >
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddBalance;
