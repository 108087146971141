const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <div>
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                Copyright © {currentYear}{' '}
                                <a>FCM</a>
                                .
                            </div>
                            <div className="col-md-6 col-sm-12 text-md-right">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                        Designed by<a href="https://webanixsolutions.com/" target="_blank"> WEBaniX Pvt Ltd</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Footer
