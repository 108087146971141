import React from "react";
import styles from "./FooterDetails.module.css";

const FooterDetails = ({ onClose ,checkoutData }) => {


  const cartData = JSON.parse(localStorage.getItem('cartData')) || [];


  return (
    <div className={styles.orderDetailsWrapper}>
      <div className={styles.detailsHeader}>
        <span>Order Details</span>
        <button
          className={styles.closeButton}
          onClick={onClose}
        >
          ✕
        </button>
      </div>
      <div className={styles.detailsContent} >
        {cartData?.map((item, index) => (
          <div key={index}>
            {item?.title} × {item?.quantity} <span>₹ {item?.price * item?.quantity}</span>
          </div>
        ))}

      
      </div>
    </div>
  );
};

export default FooterDetails;
