import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import Logo from "../../assets/images/customer_logo.png";
import { axiosPublic } from "../../hooks/axiosPublic";

const Login = () => {
  const navigate = useNavigate();

  // Form validation schema
  const loginSchema = Yup.object({
    // email: Yup.string().email().required("Email is Required"),
    mobile_email: Yup.string().required("Mobile or Email address is Required"),
    password: Yup.string().required("Password is Required"),
  });

  // Initial form values
  const initialValues = {
    // email: "",
    mobile_email: "",
    password: "",
  };

  /**
   * Handles the form submission.
   *
   * @param {Object} values - The values submitted in the form.
   * @returns {Promise} A promise that resolves when the submission is complete.
   */
  const handleSubmit = async (values) => {
    let response;

    try {
      // Send a POST request to the login endpoint with the form values.
      response = await axiosPublic.post("/login", values);

      // If the response status is 200, the login was successful.
      if (response.status === 200) {
        // Save the user data to local storage.
        localStorage.setItem("userData", JSON.stringify(response.data));

        // Show a success toast message.
        showSuccessToast("Login Successful!");

        if (response?.data?.role_name === "admin" || response?.data?.role_name === "Admin") {
          navigate("/orders");
        }
        else if (
          response?.data?.role_name === "billing person" ||
          response?.data?.role_name === "Billing Person"
        ) {
          navigate("/orders");
        } else if (
          response?.data?.role_name === "hr" ||
          response?.data?.role_name === "Hr" ||
          response?.data?.role_name === "HR"
        ) {
          navigate("/user-wallet");
        }
        else if (response?.data?.role_name === "receptionist" || response?.data?.role_name === "Receptionist") {
          navigate("/orders");
        }      
        else {
          navigate("/order-food");
        }
      }
    } catch (error) {
     
      if (error?.response?.status === 401) {
         showErrorToast("Invalid Credentials");
      }
      
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="d-flex justify-content-center align-items-center w-100 m-3">
      <div
        className='card '
        style={{ width: "350px" }}
      >
        <div className='text-center mt-1'>
          {/* Logo */}
          <Link
            className='header-brand'
            to='/'
          >
            <img
            
              src={Logo}
              alt='logo'
            />
          </Link>
        </div>

        <div className='card-body'>
          <form onSubmit={formik.handleSubmit}>
            {/* Login form */}
            <div className='card-title'>Login to your account</div>

            {/* mobile_email Number */}
            <div className='form-group'>
              <label className='form-label' htmlFor="mobile_email">
                Mobile number / Email address
              </label>
              <input
                type='text'
                name='mobile_email'
                id="mobile_email"
                className='form-control'
                value={formik.values.mobile_email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.mobile_email && formik.errors.mobile_email && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.mobile_email}
                </div>
              )}
            </div>
            {/* Password input */}
            <div className='form-group mb-0'>
              <label className='form-label' htmlFor="password">Password</label>
              <input
                type='password'
                name='password'
                id="password"
                className='form-control'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.password && formik.errors.password && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.password}
                </div>
              )}
            </div>
            {/* forgot password  */}
            <div className='d-flex w-100 justify-content-end '>
              <Link
                to='/forgot-password'
                className='btn btn-link pr-0'
              >
                Forgot Password
              </Link>
            </div>

            {/* Submit button */}
            <div>
              <input
                type='submit'
                className='btn btn-primary btn-block'
                value='Login'
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
